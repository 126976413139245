import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import sparkles from "../images/sparkles.png"

const Section = styled.section`
  background: ${props => props.background};
  .title {
    font-family: "glacial_bold";
    text-transform: uppercase;
    font-size: 3.6rem;
    font-weight: 700;
    padding-bottom: 1.6rem;
  }
  .sub-text {
    font-weight: 300;
    font-size: 2rem;
    padding-bottom: 5rem;
    text-transform: uppercase;
  }
  .light-text {
    font-weight: 400;
    font-size: 1.6rem;
    padding: 1.6rem 0;
    text-transform: uppercase;
    position: relative;
    img {
      margin-bottom: 0px;
      height: 16px;
    }
  }
`

const stepbystepmagic = ({ title, background }) => {
  return (
    <Section id="stepbystep" background={background}>
      <div className="large-container center">
        <div className="title">{title}</div>
        <div className="sub-text">Diz adeus à cola!</div>
        <div className="grid-3x">
          <div>
            <StaticImage
              src="../images/MC-PASSO1.jpg"
              alt="Magic Collection - Passo 1"
              placeholder="blurred"
              quality={90}
            />
            <div className="light-text center">Aplica o Eyeliner</div>
          </div>
          <div>
            <StaticImage
              src="../images/MC-PASSO2.jpg"
              alt="Magic Collection - Passo 2"
              placeholder="blurred"
              quality={90}
            />
            <div className="light-text center">Coloca as Pestanas</div>
          </div>
          <div>
            <StaticImage
              src="../images/MC-PASSO3.jpg"
              alt="Magic Collection - Passo 3"
              placeholder="blurred"
              quality={90}
            />
            <div className="light-text center">
              Arrasa! <img src={sparkles} alt="sparkles icon" />
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default stepbystepmagic
