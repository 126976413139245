import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import ShopifyBtn from "../components/shopify-btn"

const ProductDetail = styled.li`
  text-align: center;
  h2 {
    font-size: 1.6rem;
    padding-bottom: 0;
    color: var(--beige);
  }
  a {
    border-bottom: none;
  }
  .price {
    margin: 1.6rem 0;
    font-size: 1.6rem;
    color: rgb(209, 5, 5);
    font-weight: 700;
  }
  .std {
    color: rgb(0, 0, 0);
    font-weight: 400;
  }
  .swap-on-hover {
    position: relative;
    padding-top: 100%;
  }
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }
  .swap-on-hover__front-image {
    z-index: 998;
    transition: opacity 0.3s linear;
  }
  .swap-on-hover:hover > .swap-on-hover__front-image {
    opacity: 0;
  }
  .swap-on-hover__back-image {
    border: 4px solid #e8b795;
  }
`

const ShopGridHover = ({ product }) => {
  const sortedImages = product.images.sort((a, b) =>
    a.altText > b.altText ? 1 : b.altText > a.altText ? -1 : 0
  )
  console.log(sortedImages)
  return (
    <ProductDetail key={product.shopifyId}>
      <div className="swap-on-hover">
        <GatsbyImage
          image={getImage(sortedImages[0].localFile)}
          alt={product.title}
          className="swap-on-hover__front-image"
          placeholder="blurred"
        />
        <GatsbyImage
          image={getImage(sortedImages[1].localFile)}
          alt={`${product.title} - Before & After`}
          className="swap-on-hover__back-image"
        />
      </div>
      <div style={{ marginTop: "2rem" }}>
        <h2>{product.title}</h2>
        <div className="std price">{product.variants[0].price}€</div>
        <ShopifyBtn id={product.shopifyId} className="btn" copy="Ver Produto" />
      </div>
    </ProductDetail>
  )
}

export default ShopGridHover
