import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductGroup from "../components/productgroup-magic"
import StepByStep from "../components/stepbystepmagic"
import InstagramFeed from "../components/instagramfeed"
import { StaticImage } from "gatsby-plugin-image"
import MagicModels from "../components/magic-models"
import MagicProductGrid from "../components/magicProductGrid"

const MagicCollection = ({ data }) => {
  let magicCollection = data.allShopifyCollection.edges.filter(
    el => el.node.handle === "magic-collection"
  )[0].node.products
  magicCollection = magicCollection.filter(
    el => el.shopifyId !== "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3Nzg5MDUzMjk4NTQ="
  )
  return (
    <Layout>
      <Seo
        title="Magic Collection"
        description="A nossa linha de pestanas sem ímanes, com modelos de banda transparente e para o canto externo. Temos também 2 eyeliners - um normal e outro adesivo transparente."
      />
      <div className="mobile-only">
        <StaticImage
          src="../images/BG-MC-MB-JAY.jpg"
          alt="Boomsy Magic Collection Hero"
          placeholder="blurred"
          loading="eager"
          backgroundColor="#f1bbc2"
          layout="fullWidth"
          quality={90}
        />
      </div>
      <div className="mobile-none">
        <StaticImage
          src="../images/JAY-MC-BG.jpg"
          alt="Boomsy Magic Collection Hero"
          placeholder="blurred"
          loading="eager"
          backgroundColor="#f1bbc2"
          layout="fullWidth"
          quality={90}
        />
      </div>
      <StepByStep
        title="Simples. Prático. Aplicação em menos de 2min."
        background="#faf2de"
      />
      <MagicProductGrid products={magicCollection} />
      <ProductGroup />
      <MagicModels products={magicCollection} />
      <InstagramFeed />
    </Layout>
  )
}

export default MagicCollection

export const query = graphql`
  {
    allShopifyCollection {
      edges {
        node {
          handle
          products {
            title
            shopifyId
            description
            productType
            handle
            priceRangeV2 {
              minVariantPrice {
                amount
              }
            }
            variants {
              price
            }
            images {
              originalSrc
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 600
                    placeholder: TRACED_SVG
                    layout: CONSTRAINED
                  )
                }
              }
            }
            featuredImage {
              originalSrc
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 600
                    placeholder: TRACED_SVG
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`
