import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"

const Section = styled.section`
  background: var(--grey);
  font-size: 1.6rem;
  p {
    line-height: 2.4rem;
  }
  h1,
  h2 {
    font-family: "glacial_bold";
    font-size: 4.8rem;
    padding-bottom: 2.4rem;
    text-transform: initial;
  }
`

const ProductGroupMagic = () => {
  return (
    <Section id="products">
      <div className="container">
        <div className="grid-2x">
          <div className="center" style={{ padding: "2rem" }}>
            <StaticImage
              src="../images/MAGIC-EYELINER-ADESIVO.jpg"
              alt="Magic Eyeliner Boomsy"
              placeholder="tracedSVG"
            />
          </div>
          <div>
            <h2>Magic Eyeliner</h2>
            <p>
              Os nossos Magic Eyeliners são eyeliners em caneta{" "}
              <strong>2 em 1</strong>. Juntos, permitem que cries todo o tipo de
              looks.
            </p>
            <p>
              Podes utilizar o <strong>Magic Eyeliner Black</strong> no dia a
              dia, como eyeliner normal, ou como eyeliner adesivo para aplicares
              as tuas Pestanas Boomsy. Amazing, right?!
            </p>
            <p>
              O nosso <strong>Clear Magic Eyeliner</strong> é um Eyeliner
              Adesivo Transparente, para quem gosta daquele "pestanão" sem ter
              de utilizar uma maquilhagem muito arrojada.
            </p>
            <div className="center">
              <Link to="/loja/" className="btn">
                Ver Eyeliners
              </Link>
            </div>
          </div>
        </div>
        <div className="grid-2x reverse">
          <div>
            <h1>Pestanas Magic</h1>
            <p>
              As <strong>Pestanas Magic Boomsy</strong> são pestanas sem ímanes
              desenvolvidas para serem utilizadas em conjunto com os nossos{" "}
              <strong>Magic Eyeliners</strong>.
            </p>
            <p>
              Tens <strong>7 modelos</strong> à escolha: desde pestanas de banda{" "}
              <strong>transparente</strong> a pestanas para o{" "}
              <strong>canto externo</strong>.
            </p>
            <p>
              Com diferente comprimentos e tamanhos, vais poder criar todo o
              tipo de looks!
            </p>
            <p>
              As pestanas são <strong>vegan</strong> e{" "}
              <strong>“cruelty-free”</strong>. E podes usá-las vezes e vezes sem
              conta, visto que dão para mais de <strong>35 utilizações</strong>.
            </p>
            <div className="center">
              <AnchorLink href="#lashes" className="btn">
                Ver Modelos
              </AnchorLink>
            </div>
          </div>

          <div className="center mobile-first" style={{ padding: "2rem" }}>
            <StaticImage
              src="../images/Pestanas-Magic-Boomsy.jpg"
              alt="Pestanas Mágicas Boomsy"
              placeholder="tracedSVG"
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ProductGroupMagic
