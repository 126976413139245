import React from "react"
import styled from "styled-components"
import ShopGrid from "../components/shopGridHover"

const Section = styled.section`
  background: #fff5f1;
  .title {
    text-transform: uppercase;
    text-align: center;
    font-size: 3.6rem;
    font-weight: 400;
    margin-bottom: 6rem;
  }
`

const MagicModels = ({ products }) => {
  const magicLashes = products.filter(el => el.productType === "Pestanas")
  return (
    <Section id="lashes">
      <div className="large-container">
        <h2 className="title">
          Os nossos <span style={{ fontWeight: 700 }}>modelos</span>
        </h2>
        <ul className="grid-4x">
          {magicLashes.map(product => (
            <ShopGrid product={product} key={product.shopifyId} />
          ))}
        </ul>
      </div>
    </Section>
  )
}

export default MagicModels
