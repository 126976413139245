import React from "react"
import styled from "styled-components"
import ShopGrid from "../components/shopGrid"

const GridContainer = styled.section`
  background: #fff6f6;
  text-align: center;
  h3 {
    text-transform: uppercase;
    font-size: 3.6rem;
    font-weight: 400;
    margin-bottom: 6rem;
    strong {
      font-weight: 900;
    }
  }
`

const MagicProductGrid = ({ products }) => {
  const magicKits = products.filter(el => el.productType === "Starter Kit")
  return (
    <GridContainer>
      <div className="large-container">
        <div className="center">
          <h3>
            Os nossos <strong>MAGIC Starter Kits</strong>
          </h3>
        </div>
        <ul className="grid-4x">
          {magicKits.map(product => (
            <ShopGrid product={product} key={product.shopifyId} />
          ))}
        </ul>
      </div>
    </GridContainer>
  )
}

export default MagicProductGrid
